.not-found-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .not-found-container h1 {
    font-size: 6em;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.5em;
  }
  
  .home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }
  