@import url('https://fonts.googleapis.com/css?family=Abel');

html, body {
  background: #F2F2F2 !important;
  font-family: Abel, Arial, Verdana, sans-serif !important;
}

.navbar {
  height: 80px;
  border-bottom: 2px solid #ccc; /* Adjust color and thickness as needed */
}

.navbar a {
  font-size: 40px;
  font-weight: bold;
  text-decoration: none; /* Optional: removes underline from links */
  color: #498DFC; /* Optional: allows link color to inherit from parent */
}
