/* Footer.css */
.footer {
    background-color: #222;
    color: #bbb;
    padding: 10px 0;
    font-size: 0.8rem;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.footer-links, .footer-social {
    display: flex;
    gap: 10px;
}

.footer-links a, .footer-social a {
    color: #bbb;
    text-decoration: none;
    font-size: 0.8rem;
    transition: color 0.3s;
}

.footer-links a:hover, .footer-social a:hover {
    color: #fff;
}

/* Adjust the padding at the bottom of the main content to prevent overlap */
.main-content {
    padding-bottom: 50px; /* Adjust based on footer height */
}
